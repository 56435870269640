<template>
  <div>
    <!-- <van-nav-bar
      left-text="返回"
      left-arrow
      @click-left="goBack"
      title="发布"
      right-text="保存"
      @click-right="addTopic"
    /> -->
    <van-field
      v-model="title"
      label="主题"
      placeholder="请输入主题"
      :rules="[{ required: true, message: '请输入主题' }]"
    />
    <van-field
      readonly
      clickable
      name="picker"
      :value="value"
      label="版块"
      placeholder="点击选择版块"
      @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="sectionList"
        value-key="SectionName"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <div class="editor">
      <!-- <div ref="toolbar" class="toolbar"></div>
      <div ref="editor" class="text"></div> -->
      <div class="edit_container">
        <template>
          <quill-editor
            v-model="content"
            ref="myQuillEditor"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange($event)"
          >
          </quill-editor>
          <el-upload
            v-show="false"
            class="upload-demo uploadFile"
            action="str"
            :http-request="postImagesUploadPic"
          >
            <el-button size="small" type="primary">上传文件</el-button>
          </el-upload>
        </template>
      </div>
    </div>

    <div class="sub_btn">
      <el-button type="primary" @click="addTopic">保 存</el-button>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
import { saveTopic } from "@/api/bbs";
import { imagesUploadPic } from "@/api/api";
import { quillEditor, Quill } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

// 自定义插入a链接
var Link = Quill.import("formats/link");
class FileBlot extends Link {
  // 继承Link Blot
  static create (value) {
    let node = undefined;
    if (value && !value.href) {
      // 适应原本的Link Blot
      node = super.create(value);
    } else {
      // 自定义Link Blot
      node = super.create(value.href);
      // node.setAttribute('download', value.innerText);  // 左键点击即下载
      node.innerText = value.innerText;
      node.download = value.innerText;
    }
    return node;
  }
}
FileBlot.blotName = "link";
FileBlot.tagName = "A";
Quill.register(FileBlot);
// 编辑器工具栏配置
const toolbarOptions = [
  // ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
  // ['blockquote', 'code-block'],     //引用，代码块
  // [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
  // [{ list: "ordered" }, { list: "bullet" }], //列表
  // [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
  // [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{ 'direction': 'rtl' }],             // 文本方向
  // [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
  // [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
  // [{ 'font': [] }],     //字体
  [{ align: [] }], //对齐方式
  ["link", "image"], //, "upload"  //上传图片、上传视频
  ["clean"], //清除字体样式
];
let self = this;
let state = 0;
export default {
  name: "editor",
  components: {
    quillEditor,
  },
  data () {
    return {
      sectionId: 0,
      value: '',
      content: "",
      sectionList: [],
      editor: null,
      info_: null,
      title: "",
      showPicker: false,
      userId: 0,
      editorOption: {
        placeholder: "请在这里输入您的发帖内容",
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                //编辑器-上传图片
                if (value) {
                  state = 1;
                  document.activeElement.blur();
                  document.querySelector(".uploadFile input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
              // upload: (value) => {
              //   //编辑器-上传文件
              //   if (value) {
              //     state = 2;
              //     document.activeElement.blur();
              //     document.querySelector(".uploadFile input").click();
              //   }
              // },
            },
          },
        },
      },
    };
  },
  props: {},
  watch: {
    isClear (val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear();
        this.info_ = null;
      }
    },
    // value: function (value) {
    //   if (value !== this.editor.txt.html()) {
    //     this.editor.txt.html(this.value);
    //   }
    // },
    //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  mounted () {
    this.sectionList = JSON.parse(this.$route.query.sectionList);
    this.sectionId = this.sectionList[0].Id;
    this.value = this.sectionList[0].SectionName;
    this.userId = this.$route.query.userId;
    console.log(this.sectionList);
    // this.seteditor();
  },
  methods: {
    // 上传文件
    async postImagesUploadPic (param) {
      let newFile = await this.common.compressImg(param.file);
      const formData = new FormData();
      formData.append("file", newFile);
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let quill = this.$refs.myQuillEditor.quill;
        let length = quill.getSelection().index;
        // console.log(state, "");
        if (state == 1) {
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(
            length,
            "image",
            this.GLOBAL.hostUrl + res.response
          );
        } else if (state == 2) {
          // 插入文件，res为服务器返回的文件链接地址
          quill.insertEmbed(length, "link", {
            href: this.GLOBAL.hostUrl + res.response,
            innerText: param.file.name,
          });
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        // console.log(res.response, '')
        // console.log(this.content, "");
      } else {
        this.$message.error(res.msg);
      }
    },
    onEditorBlur () { }, // 失去焦点事件
    onEditorFocus () { }, // 获得焦点事件
    onEditorChange () {
      console.log(this.content, "");
    },
    seteditor () {
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.editor.customConfig.uploadImgServer =
        this.GLOBAL.hostUrl + "images/Upload/Pic"; // 配置服务器端地址
      // 配置菜单
      this.editor.customConfig.menus = [
        "head", // 标题
        // "fontSize", // 字号
        // "foreColor", // 文字颜色
        // "backColor", // 背景颜色
        "justify", // 对齐方式
        "emoticon", // 表情
        "image", // 插入图片
      ];

      this.editor.customConfig.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功，插入图片的回调
          //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
          //这块一定要细心，因为会影响到上传图片成功之后的回显
          console.log(result);
          let url = this.GLOBAL.hostUrl + result.response;
          insertImg(url);
          // }
        },
      };

      this.editor.customConfig.onchange = (html) => {
        this.info_ = html; // 绑定当前逐渐地值
        // this.$emit("change", this.info_); // 将内容同步到父组件中
      };
      // 创建富文本编辑器
      this.editor.create();
    },
    onConfirm (value) {
      this.value = value.SectionName;
      this.sectionId = value.Id;
      this.showPicker = false;
    },
    async addTopic () {
      if (this.title == "") {
        this.$message.info("请输入主题");
        return false;
      }
      if (this.sectionId == 0) {
        this.$message.info("请选择版块");
        return false;
      }
      if (this.info_ != "") {
        let parm = {
          SectionId: this.sectionId,
          CreateUserId: this.userId,
          TopicTitle: this.title,
          TopicContent: this.content,
        };
        const res = await saveTopic(parm);
        if (res.success == true) {
          this.$message.success("发布成功");
          this.$router.push({
            path: "/h5/bbs/Index",
            query: { UserId: this.userId },
          });
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.$message.info("请输入发布内容");
      }
    },
    goBack () {
      this.$router.push({
        path: "/h5/bbs/Index",
        query: { UserId: this.userId },
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.editor {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  background-color: white;
}
.toolbar {
  border: 1px solid #ebebeb;
}
.text {
  border: 1px solid #ebebeb;
  min-height: 400px;
}

.sub_btn {
  margin: 20px auto;
  text-align: center;
  button {
    width: 280px;
    font-size: 15px;
    height: 42px;
    background: #4481fe;
    box-shadow: 0px 4px 6px rgba(22, 121, 253, 0.22);
    border-radius: 30px;
  }
}

/deep/.ql-editor {
  min-height: 100px !important;
}
/deep/.quill-editor {
  .ql-toolbar {
    .ql-formats {
      margin-right: 0;
    }
  }
}
</style>
